'use client';

import dynamic from 'next/dynamic';
import { ComponentType } from 'react';

// Only include the localhost redirect code when running locally
let DevOnlyRedirect: ComponentType = () => null;
if (process.env.NODE_ENV === 'development') {
	DevOnlyRedirect = dynamic(async () => import('./LocalHostRedirectDev'), {
		ssr: false,
	});
}

export function LocalHostRedirect() {
	return <DevOnlyRedirect />;
}
